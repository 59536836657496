<template>
  <el-row class="row" type="flex" justify="center">
    <el-col class="form-content">
      <el-form v-loading="loading" size="middle" class="teacher-form" :model="formData" ref="postForm" label-width="100px" label-position="right" :rules="rules" :disabled="isView">
        <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">基本信息</div>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item prop="Code" label="工号">
              <el-autocomplete v-model="formData.Code" :trigger-on-focus="false" :fetch-suggestions="searchTeacher" placeholder="请输入工号" clearable style="width:100%" @select="handleSelect" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Name" label="姓名">
              <el-input v-model="formData.Name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Sex" label="性别">
              <el-select v-model="formData.Sex" placeholder="请选择性别" style="width:100%">
                <el-option v-for="item in sexOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Phone" label="电话">
              <el-input v-model="formData.Phone" placeholder="请输入电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Email" label="邮箱">
              <el-input v-model="formData.Email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="TypeId" label="导师类型">
              <el-select v-model="formData.TypeId" placeholder="请选择导师类型" style="width:100%">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Title" label="职称">
              <el-select v-model="formData.Title" placeholder="请选择导师职称" style="width:100%">
                <el-option v-for="item in titleOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Education" label="最高学历">
              <el-select v-model="formData.Education" placeholder="请选择导师最高学历" style="width:100%">
                <el-option v-for="item in educationOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="TeachYear" label="任教年份">
              <el-input v-model.number="formData.TeachYear" type="number" :min="1" :max="100" placeholder="请输入任教年份"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="TeachCourse" label="担任课程">
              <el-input v-model="formData.TeachCourse" placeholder="请输入担任课程"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Expertise" label="专业特长">
              <el-input v-model="formData.Expertise" placeholder="请输入专业特长"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="HonorIds" label="教师荣誉">
              <el-select v-model="formData.HonorIds" multiple placeholder="请选择教师荣誉" style="width:100%">
                <el-option v-for="item in honorOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">教师照片</div>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="教师照片" prop="Photo">
              <el-image style="width: 120px; height: 120px" :src="formData.PhotoFullPath" v-if="isView"></el-image>
              <picture-selector v-else v-model="formData.Photo" name="Photo" ref="Photo" remarks="120*120" @input="updateForm" :fullPath="formData.PhotoFullPath">
              </picture-selector>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">教师介绍</div>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="教师简介" prop="Introduction">
              <div v-if="isView" v-html="formData.Introduction"></div>
              <rich-text v-else v-model="formData.Introduction" name="Introduction" @input="updateForm"></rich-text>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-col class="mb-40 ml-100" :span="24">
        <el-button v-if="!isView" size="large" type="primary" @click="submitForm('postForm')">确定</el-button>
        <el-button size="large" @click="cancle">{{
          isView ? "返回" : "取消"
        }}</el-button>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import PictureSelector from "@/components/base/PictureSelector";
import RichText from "@/components/base/RichText";
import { isEmail, isPhone } from "@/utils/validate";
import { getTeacherByCode, getInfo, updateEntity } from "@/api/teacher";
import { insertTeacher } from "@/api/organization";
import { getDicByCode } from "@/api/base-data";
export default {
  components: {
    PictureSelector,
    RichText,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    orgId: {
      type: String,
      default: "",
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("请输入正确的邮箱地址"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      isConnect: false,
      formData: {
        Name: "",
        Photo: "",
        Introduction: "",
      },
      sexOptions: [],
      honorOptions: [],
      typeOptions: [],
      titleOptions: [],
      educationOptions: [],
      rules: {
        Name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        Code: [{ required: true, message: "请输入工号", trigger: "blur" }],
        Sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        Phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        // Email: [{ required: true, trigger: "blur", validator: validateEmail }],
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.formData[fieldName] = value;
      // console.log(this.formData)
    },
    handleSelect(item) {
      this.isConnect = true;
      console.log(item);
      this.formData = Object.assign({}, item.origin);
      this.formData.OrganizationId = this.orgId;
    },
    async searchTeacher(query, cb) {
      this.isConnect = false;
      if (query) {
        const { Result } = await getTeacherByCode({ code: query });
        if (Result != null) {
          console.log("Result: ", Result);
          const cbArr = [];
          cbArr.push({ value: Result.Name, origin: Result });
          cb(cbArr);
        } else {
          cb([]);
        }
      } else {
        cb([]);
      }
    },
    async initialize() {
      this.loading = true;
      try {
        // 新增和编辑时需要获取下拉配置
        Promise.all([
          this.getSex(),
          this.getType(),
          this.getTitle(),
          this.getEdu(),
          this.getHor(),
        ]);
        // 查看和编辑时需要获取详情
        if (this.isView || this.isEdit) {
          const res = await getInfo({ id: this.id });
          if (res.IsSuccess) {
            this.formData = Object.assign({}, res.Result);
            console.log(this.formData);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getSex() {
      const res = await getDicByCode({ code: "2001" });
      this.sexOptions = res.Result.Details.map((item) => ({
        label: item.Name,
        value: parseInt(item.Value),
      }));
    },
    async getType() {
      const res = await getDicByCode({ code: "10015" });
      this.typeOptions = res.Result.Details.map((item) => ({
        label: item.Name,
        value: item.Value,
      }));
    },
    async getTitle() {
      const res = await getDicByCode({ code: "10014" });
      this.titleOptions = res.Result.Details.map((item) => ({
        label: item.Name,
        value: item.Value,
      }));
    },
    async getEdu() {
      const res = await getDicByCode({ code: "10012" });
      this.educationOptions = res.Result.Details.map((item) => ({
        label: item.Name,
        value: item.Value,
      }));
    },
    async getHor() {
      const res = await getDicByCode({ code: "10016" });
      this.honorOptions = res.Result.Details.map((item) => ({
        label: item.Name,
        value: item.Value,
      }));
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          const postData = Object.assign({}, this.formData);
          try {
            postData.OrganizationId = this.orgId;
            if (this.isEdit) {
              const res = await updateEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const res = await insertTeacher(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误",
          });
          return;
        }
      });
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  flex: 1;
}
.form-content {
  width: 900px;
}
.teacher-form {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;
  ::v-deep {
    .el-form-item {
      line-height: 40px;
    }
  }
}
</style>
